import { ContentBlockType } from '@tytapp/content';
import { HonorRollBlock } from './honor-roll-block';

export * from './honor-roll-block';

export const HONOR_ROLL_BLOCK_TYPE: ContentBlockType<HonorRollBlock> = {
    id: 'honor-roll',
    label: 'Amplify Honor Roll',
    icon: 'emoji_events',
    template: {},
    preview: {},
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    categoryId: 'community',
    deferredEditorComponent: () => import('./honor-roll-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./honor-roll-block.view').then(m => m.ViewComponent)
};
