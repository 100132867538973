import { ContentBlockType } from '@tytapp/content';
import { AnchorBlock } from './anchor-block';

export * from './anchor-block';
export const ANCHOR_BLOCK_TYPE: ContentBlockType<AnchorBlock> = {
    id: 'anchor',
    label: 'Anchor',
    icon: 'title',
    preview: {
        isPreview: true
    },
    previewSettings: {
        verticalAlign: 'center',
        horizontalAlign: 'center'
    },
    categoryId: 'layout',
    deferredEditorComponent: () => import('./anchor-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./anchor-block.view').then(m => m.ViewComponent)
};
