import { ContentBlockType } from '@tytapp/content';
import { ConditionalBlock } from './conditional-block';

export * from './conditional-block';

export const CONDITIONAL_BLOCK_TYPE: ContentBlockType<ConditionalBlock> = {
    id: 'conditional',
    label: 'Conditional Container',
    icon: 'dashboard',
    isLayout: true,
    preview: {
        branches: []
    },
    template: {
        branches: [
            {
                condition: {
                    type: 'complex',
                    conditions: [],
                    operator: 'and'
                },
                blocks: []
            }
        ]
    },
    categoryId: 'layout',
    deferredEditorComponent: () => import('./conditional-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./conditional-block.view').then(m => m.ViewComponent)
};
