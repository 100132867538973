import { ContentBlockType } from '@tytapp/content';
import { HeroBlock } from './hero-block';

export * from './hero-block';

export const HERO_BLOCK_TYPE: ContentBlockType<HeroBlock> = {
    id: 'hero',
    label: 'Hero',
    icon: 'newspaper',
    template: {
        alignment: 'left'
    },
    preview: {
        alignment: 'left',
        heading: 'Heading',
        image: '/assets/operation-hope/support-c2a-bg.png',
        overlayHeading: true,
        text: 'Lorem ipsum dolor sit amet.'
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    categoryId: 'user-defined',
    deferredEditorComponent: () => import('./hero-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./hero-block.view').then(m => m.ViewComponent)
};
