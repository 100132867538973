import { ContentBlockType } from '@tytapp/content';
import { CommentBlock } from './comment-block';

export * from './comment-block';

export const COMMENT_BLOCK_TYPE: ContentBlockType<CommentBlock> = {
    id: 'note',
    label: 'Note (Hidden from viewers)',
    icon: 'code',
    template: {
        content: ''
    },
    preview: {
        content: `
            This is a note which is never shown to the user.
        `
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    categoryId: 'community',
    deferredEditorComponent: () => import('./comment-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./comment-block.view').then(m => m.ViewComponent),
};
