import { ContentBlockType } from '@tytapp/content';
import { CarouselBlock } from './carousel-block';

export * from './carousel-block';

export const CAROUSEL_BLOCK_TYPE: ContentBlockType<CarouselBlock> = {
    id: 'carousel',
    label: 'Carousel',
    icon: 'view_carousel',
    template: {
        slides: [],
        selectionMode: 'homePage'
    },
    preview: {
        slides: [
            {
                image_desktop: '/assets/offer_bg_short.png',
                image: '/assets/offer-bg-2020.png',
                intended_audience: ['visitor', 'registered', 'member', 'expired member']
            }
        ],
        selectionMode: 'custom'
    },
    categoryId: 'user-defined',
    deferredEditorComponent: () => import('./carousel-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./carousel-block.view').then(m => m.ViewComponent)
}
