import { ContentBlock, ContentBlockType } from '@tytapp/content';

export interface OfferSetProgressBlock extends ContentBlock {
    offerId: string;
    pogType: string;
}

export const OFFER_SET_PROGRESS_BLOCK_TYPE: ContentBlockType<OfferSetProgressBlock> = {
    id: 'offer-set-progress',
    label: 'Offer Set Progress (POG)',
    icon: 'request_quote',
    template: {

    },
    preview: {
        offerId: 'default',
        pogType: 'membership'
    },
    previewSettings: {
        fade: true
    },
    categoryId: 'conversion',
    deferredEditorComponent: () => import('./offer-set-progress-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./offer-set-progress-block.view').then(m => m.ViewComponent)
};
