import { ElementRef, Injectable, NgZone, inject } from '@angular/core';
import { ApiAvAsset } from '@tytapp/api';
import { LoggerService } from '@tytapp/common';
import { AssetResolution, MediaItem, MediaService, PlaybackHints, PlaybackSession } from '@tytapp/media-playback';
import { YouTubeLoader } from './youtube-loader';
import { YouTubePlaybackSession } from './youtube-playback-session';


/**
 * YouTube playback service
 */
@Injectable()
export class YouTubePlaybackService implements MediaService {
    private zone = inject(NgZone);
    private logger = inject(LoggerService);

    get id() { return 'youtube'; }

    resolve(item: MediaItem): AssetResolution {
        let asset: ApiAvAsset = item.item.asset;
        let url = asset.url;

        url = url
            .replace(/^https?:\/\//, '')
            .replace(/^www\./, '')
            .replace(/^youtu.be\//, 'youtube.com')
            ;

        if (!url.startsWith('youtube.com/watch?v='))
            return null;

        let params = {};

        url.replace(/^.*\?/, '').split('&').map(x => x.split('=', 2)).forEach(pair => {
            params[pair[0]] = decodeURIComponent(pair[1]);
        });

        let id = params['v'];
        if (!id)
            return null;

        params['id'] = params['v'];

        return {
            item,
            service: this,
            asset,
            data: params
        };
    }

    private styles = `
        .youtube-player {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        .youtube-player iframe {
            height: 100%;
            position: absolute;
            width: 100%;
        }
    `;

    private styleElement: HTMLElement = null;

    /**
     * Play the given media resolution within the given root element.
     */
    play(mediaResolution: AssetResolution, rootElement: ElementRef, playbackHints: PlaybackHints): Promise<PlaybackSession> {
        // Ensure the global CSS styles are installed.

        if (typeof document !== 'undefined') {
            let styleEl = this.styleElement;

            if (!styleEl) {
                styleEl = document.getElementById(`youtube-styles`);
                if (!styleEl) {
                    styleEl = document.createElement('style');
                    styleEl.innerHTML = this.styles;
                    styleEl.id = 'youtube-styles';
                    document.head.appendChild(styleEl);
                }

                this.styleElement = styleEl;
            }
        }

        // Construct a new element to hold the YouTube iframe

        let element = <HTMLElement>rootElement.nativeElement;
        let playerId = 'ytplayer_' + Math.floor(Math.random() * 10000);
        let id = mediaResolution.data['id'];

        element.innerHTML = `<div class="youtube-player"><div id="${playerId}"></div></div>`;

        // Initialize the player and wait for it to be ready...

        let autoplay = playbackHints.autoplay !== false;

        return new Promise<PlaybackSession>((resolve, reject) => {
            let session: YouTubePlaybackSession = null;

            YouTubeLoader.load(YT => {
                let player = new YT.Player(playerId, {
                    height: '100%',
                    width: '100%',
                    videoId: id,
                    playerVars: {
                        controls: 0,
                        autoplay: 0, // must be handled below for muting
                        playsinline: 1,
                        origin: document['origin'],
                        cc_load_policy: 1,
                        cc_lang_pref: '',
                        showinfo: 0
                    },
                    events: {
                        onReady: () => {
                            if (autoplay) {
                                if (playbackHints.startMuted) {
                                    player.mute();
                                }
                                player.playVideo();
                            }

                            session = new YouTubePlaybackSession(
                                this, mediaResolution, element.querySelector('.youtube-player'),
                                player, this.zone, playbackHints && playbackHints.isLive ? true : false
                            );

                            if (playbackHints && playbackHints.startPosition)
                                session.seek(playbackHints.startPosition);

                            resolve(session);
                        },
                        onStateChange: event => session.handlePlayStateChanged(event.data),
                        onError: ev => {
                            this.logger.error(`YouTube has failed to start:`);
                            this.logger.inspect(ev);

                            reject(new Error('Error occurred while preparing YouTube player: '));
                        },
                        onPlaybackRateChange: ev => this.zone.runGuarded(() => session.onPlaybackRateChange(ev))
                    }
                });

            });
        });
    }
}
