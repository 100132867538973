import { ContentBlockType } from '@tytapp/content';
import { SectionTagBlock } from './section-tag-block';

export * from './section-tag-block';

export const SECTION_TAG_BLOCK_TYPE: ContentBlockType<SectionTagBlock> = {
    id: 'section-tag',
    label: 'Section Tag',
    icon: 'label',
    template: {
       text: 'Section Tag'
    },
    preview: {
        text: 'The quick brown fox jumps over the lazy dog'
    },
    previewSettings: {
        horizontalAlign: 'center',
        verticalAlign: 'center'
    },
    categoryId: 'layout',
    deferredEditorComponent: () => import('./section-tag-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./section-tag-block.view').then(m => m.ViewComponent)
};
