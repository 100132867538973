import { ContentBlockType } from '@tytapp/content';
import { TweetBlock } from './tweet-block';

export * from './tweet-block';

export const TWEET_BLOCK_TYPE: ContentBlockType<TweetBlock> = {
    id: 'tweet',
    label: 'Tweet',
    icon: 'title',
    preview: {
        isPreview: true,
        html_embed: ''
    },
    categoryId: 'user-defined',
    deferredEditorComponent: () => import('./tweet-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./tweet-block.view').then(m => m.ViewComponent),
};
