import { ContentBlockType } from '@tytapp/content';
import { TextingOptinBlock } from './texting-optin-block';

export * from './texting-optin-block';

export const TEXTING_OPTIN_BLOCK_TYPE: ContentBlockType<TextingOptinBlock> = {
    id: 'texting-optin',
    label: 'Texting Opt-In',
    icon: 'smartphone',
    template: {
    },
    preview: {
    },
    editable: false,
    categoryId: 'user-defined',
    deferredEditorComponent: () => import('./texting-optin-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./texting-optin-block.view').then(m => m.ViewComponent)
}
