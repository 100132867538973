import { ContentBlockType } from '@tytapp/content';
import { OnboardingC2aBlock } from './onboarding-c2a-block';

export * from './onboarding-c2a-block';

export const ONBOARDING_C2A_BLOCK_TYPE: ContentBlockType<OnboardingC2aBlock> = {
    id: 'onboarding-c2a',
    label: 'Onboarding C2A',
    icon: 'rocket',
    editable: false,
    template: {
    },
    preview: {
    },
    categoryId: 'conversion',
    deferredEditorComponent: () => import('./onboarding-c2a-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./onboarding-c2a-block.view').then(m => m.ViewComponent)
}
